/* GENERAL STYLING  */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
}

/* The rest of the existing styles below */
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden;      */ /* prevents vertical scroll to appear */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.parallax {
    /* Set up the background image with cover size and fixed attachment */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh; /* Adjust the height as needed */
    width: 100%; /* Ensure it covers the full width */
    object-fit: cover;
    position: absolute;
      top: 0;
  left: 0;
}

/* END OF GENERAL STYLING  */



.btn-transparent:hover {
  background-color: #2c3e50;
  color: #fff;
}


/* STYLING FOR RESPONSIVE CHARTS */

@media (max-width: 600px) {
  .chart-container {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (min-width: 601px) {
  .chart-container {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
/* responsive horizontal bar chart */
@media (max-width: 576px) {
  .h-sm-75,
  .w-sm-75 {
    height: 75% !important;
    width: 75% !important;
  }
}
/* responsive doughnut chart*/

/* @media (max-width: 320px) {
  .h-sm-90,
  .w-sm-90 {
    max-width: 90% !important;
  }
} */


/* END OF STYLING FOR RESPONSIVE CHARTS */


/* SET UP THE ORDER IN WHICH ELEMENTS ARE GENERATED IN MOBILE VIEW */

@media (min-width: 768px) {
.mobile-only {
  display: none;
}
}

@media (min-width: 577px) and (max-width: 767px){
.medium-only {
    display: none;
}
}



@media (max-width: 767px) {
  .medium-and-larger-screens-only {
    display: none;
  }
  }

  @media (min-width: 768px) {
    .medium-and-larger-screens-only {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    }
  

/* END OF SETTING UP THE ORDER IN WHICH ELEMENTS ARE GENERATED IN MOBILE VIEW */


/* RESPONSIVE LEGEND ICONS */

@media (min-width: 1200px) {
  .legend-icons {
    min-width: 35px;
    height: 100%;
  }
}

@media (max-width: 1199px) {
  .legend-icons {
    height: 100%;
    width: 29px;
  }
}

/* END OF RESPONSIVE LEGEND ICONS */




/* ANIMATIONS - NOT COVERED BY FRAMER MOTION */


.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

/* END OF ANIMATIONS - NOT COVERED BY FRAMER MOTION */



/* RESPONSIVE LOGO ON TOP OF THE DASHBOARD */

/* @media (min-width: 1200px) {
  .legend-icons {
    min-width: 35px;
    height: 100%;
  }
} */

@media (max-width: 2000px) {
  .logo {
    max-height: 35px;
  }
}

/* END OF RESPONSIVE LOGO ON TOP OF THE DASHBOARD */


/*  COLORS OF THE ICONS ON THE LEAFLET MAP AND IN THE LEGEND*/

 /* class "cls-2" controls the left side background of the tree icons */

.legend-icons .blue svg .cls-2 {
  fill: #79E0EE;
}

.legend-icons .transparent svg .cls-2 {
  fill: transparent;
}

.legend-icons .white svg .cls-2 {
  fill: white;
}

.legend-icons .gray svg .cls-2 {
  fill: gray;
}

.legend-icons .green-left svg .cls-2 {
  fill: rgb(25, 135, 84);
}

.legend-icons .yellow-left svg .cls-2 {
  fill: #FDFF00;
}

.legend-icons .red-left svg .cls-2 {
  fill: red;
}

 /* class "cls-1" controls the left side background of the tree icons */


 .legend-icons .green-right svg .cls-1 {
  fill: rgb(25, 135, 84);
}

.legend-icons .yellow-right svg .cls-1 {
  fill: #FDFF00;
}

.legend-icons .red-right svg .cls-1 {
  fill: red;
}


.blurred-icon {
  filter: blur(4px);
  opacity: 1;
}

/*  END OF COLORS OF THE ICONS ON THE LEAFLET MAP AND IN THE LEGEND*/


/*  DOUGHNUT CHART STYLING*/

.gauge-chart {
  max-height: 100%;
  max-width: 100%;
}

@media (max-width: 767px) {
.gaugeChartsTrees  {
  max-width: 80%;
}
}




@media (min-width: 768px) {
.tree-gauge-container-md-and-larger {
  flex-direction: column;
  
}
}


  @media (max-width: 767px) {
    .tree-gauge-child-md-and-larger {
      width: calc(50% - 5px)

    }
    }


    @media (min-width: 768px) {
      .tree-gauge-child-md-and-larger {
        height: 48.5%
  
      }
      }
/*  END OF DOUGHNUT CHART STYLING*/



/*  SWITCH STYLING (SWITCHES ON WEATHER DASHBOARD*/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-burg {
  background-color: rgba(75, 192, 192, 1);
}

input:focus + .slider-burg {
  box-shadow: 0 0 1px rgba(75, 192, 192, 1);
}

input:checked + .slider-gymnasium {
  background-color: rgba(153, 102, 255, 1);
}

input:focus + .slider-gymnasium {
  box-shadow: 0 0 1px rgba(153, 102, 255, 1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*  END OF SWITCH STYLING (SWITCHES ON WEATHER DASHBOARD*/

/*   LEGEND OF THE WEATHER MAP */


.legend {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

/* END OF LEGEND OF THE WEATHER MAP */


/*   CUSTOM NAVBAR */

.nav-link {
  color: white;
}

.custom-dropdown .dropdown-toggle {
  color: white !important;
}

.custom-dropdown .dropdown-toggle::after {
  color: white !important;
}

.custom-dropdown .dropdown-toggle:hover,
.custom-dropdown .dropdown-toggle:focus {
  color: white !important;
}

.custom-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler {
  border: solid !important;
  border-color: #5D7280 !important;
  outline-color: white;
}

.navbar-toggler:focus {
  outline: none !important;
}


/* END OF LEGEND OF THE WEATHER MAP */


/* LOCK IN PORTRAIT ORIENTATION */


@media  (max-width: 768px) {
  .landscape-message {
    display: none;
  }

  @media (orientation: landscape) {
    .landscape-message {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: white;
      color: black;
      font-size: 24px;
      z-index: 9999;
    }

    .content {
      display: none;
    }
  }
}

.landscapeWarning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #5D7280;
  color: lightgray;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
}


/* END OF LOCK IN PORTRAIT ORIENTATION */




/* CHATBOT CONFIGURATION */
.chatbot-container {
  max-width: 20%; /* Ensure it doesn't overflow on smaller screens */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: fixed; /* Position it fixed */
  bottom: 20px; /* Distance from bottom */
  right: 20px; /* Distance from right */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  z-index: 1000; /* Ensure it stays on top */
}




.chatbot-header {
  background-color: rgb(220, 53, 69)
  ;
  color: white;
  padding: 10px;
  text-align: center;
}

.chatbot-messages {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  max-height: 300px; /* Adjust as needed */
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%; /* Limit width */
  word-wrap: break-word; /* Break long words */
}

.user-message {
  background-color: #d1e5ff; /* User message background */
  align-self: flex-end; /* Align user messages to the right */
}

.bot-message {
  background-color: #f1f1f1; /* Bot message background */
  align-self: flex-start; /* Align bot messages to the left */
}


/* END OF CHATBOT CONFIGURATION */


.icon {
  height: 100%; /* Set height to 100% to match the dropdown */
  width: auto; /* Maintain aspect ratio */
  max-height: 30px; /* Set a max height to control size */
  margin-right: 8px; /* Space between the icon and the dropdown */

}

.custom-dropdown2 {
  background-color: transparent!important;
  border-color:  lightgray!important;
  color:  lightgray!important;
}



